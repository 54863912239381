import React from 'react';

import './Explore.css';

function Explore(props) {
  return (
    <div className="explore">
      <p>This is explore</p>
    </div>
  );
}

export default Explore;
